import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

/**
 * gcbs icon component.
 *
 * The component renders the gcbs icon
 *
 * @param {*} props 
 */
const GcbsIcon = ({
  ...rest
}) => {

  return <SvgIcon {...rest} viewBox="0 0 2834.65 2834.65">
    <g>
      <path d="M1389.1,2551.17c-528.64.56-997.84-394-1089.36-934.57-98.91-584.32,262-1144,837.1-1296.13,179.81-47.58,361-48.69,542.28-6.72,20.47,4.74,41.82,10.34,59.65,20.79,50.38,29.56,65.18,76.19,43.74,127.46-23,55.09-76.65,84.31-130.38,72.9-145.41-30.88-291.81-39.74-437.2-3.82-340.08,84-564.64,297.77-666.85,626.85-152.82,492,143.84,1007.69,644.44,1139.45,512,134.76,1041.55-203.3,1128.86-721.71,8.95-53.11,12.4-107.34,15.25-161.21,4.34-82.09,78.44-131.25,154.65-100.57,38.36,15.44,60,47.58,59.9,88.88-1.4,481.81-279.79,892.59-727.57,1071.37C1691.57,2526.86,1553.61,2551.7,1389.1,2551.17Z" />
      <path d="M1422.76,1589.11c52.85-60.07,103.29-117.27,153.59-174.59q277.68-316.47,555.32-633c33-37.54,83.78-44.58,131.35-18.9,51.51,27.81,71.08,69.76,54.32,124.27-6.06,19.73-16.34,39.89-29.88,55.36q-384.34,439.1-769.95,877.08c-25.53,29.09-53.14,51.61-95,50.92-29.28-.48-54.36-9.26-74.85-29.59-116.05-115.16-232.31-230.13-347.55-346.1-44.92-45.2-43.33-110.61.9-154.95s109-46.18,155.81-2.37c80.45,75.3,159.86,151.7,239.74,227.61C1404.18,1572.14,1412,1579.19,1422.76,1589.11Z" />
    </g>
  </SvgIcon>;
}

export default GcbsIcon;