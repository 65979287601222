import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'it',
    //debug: true,
    ns: 'gcbs',
    defaultNS: 'gcbs',

    load: 'languageOnly', // only locale without subdivision - it instead of it-IT

    keySeparator: '|',
    nsSeparator: '^',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: '/locales/{{ns}}/{{lng}}.json',
      queryStringParams: { v: window._env_.APP_VERSION } // cache bust
    }
  });

export default i18n;