const indexedDB = {
  name: 'gcbsDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'user',
      storeConfig: { keyPath: 'key', autoIncrement: false },
      storeSchema: [
        { name: 'key', keypath: 'key', options: { unique: true } },
        { name: 'token', keypath: 'token', options: { unique: false } },
        { name: 'user', keypath: 'user', options: { unique: false } },
      ],
    },
  ]
};

export default indexedDB;