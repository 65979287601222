import React from 'react';
import loadable from '@loadable/component';

import CircularProgress from '@mui/material/CircularProgress';

/**
 * Create a fallback loader
 * @param {*} param 
 * @returns 
 */
const Fallback = ({
  ...rest
}) => {
  return <CircularProgress
    size={40}
    status={'loading'}
    style={{ margin: '45vh calc( 50% - 20px )' }}
  />;
};

const routes = [
  { // home shows the most interesting things :)
    path: '/',
    exact: true,
    component: loadable(() => import('../views/ActiveEvents/ActiveEvents.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // privacy
    path: '/privacy',
    exact: true,
    component: loadable(() => import('../views/Privacy/Privacy.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // register
    path: '/register',
    exact: true,
    component: loadable(() => import('../views/Register/Register.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // verify email
    path: '/verify',
    exact: true,
    component: loadable(() => import('../views/VerifyEmail/VerifyEmail.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // forgot password
    path: '/forgot',
    exact: true,
    component: loadable(() => import('../views/ForgotPassword/ForgotPassword.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // reset password
    path: '/reset',
    exact: true,
    component: loadable(() => import('../views/ResetPassword/ResetPassword.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // current user
    path: '/me',
    exact: true,
    component: loadable(() => import('../views/CurrentUser/CurrentUser.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // create organization
    path: '/create-organization',
    exact: true,
    component: loadable(() => import('../views/CreateOrganization/CreateOrganization.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // organization
    path: '/o/:slug',
    exact: true,
    component: loadable(() => import('../views/Organization/Organization.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // create event
    path: '/o/:organizationId/create-event',
    exact: true,
    component: loadable(() => import('../views/CreateEvent/CreateEvent.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // organization events
    path: '/o/:organizationId/events',
    exact: true,
    component: loadable(() => import('../views/Events/Events.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event settings
    path: '/event/:eventId/settings',
    exact: true,
    component: loadable(() => import('../views/EventSettings/EventSettings.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event roles
    path: '/event/:eventId/roles',
    exact: true,
    component: loadable(() => import('../views/EventRoles/EventRoles.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // 404
    component: loadable(() => import('../views/FourZeroFour/FourZeroFour.jsx'), {
      fallback: <Fallback />
    }),
  },
];

export default routes;